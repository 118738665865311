<script lang="ts">
    import { SpeedDial, SpeedDialButton } from 'flowbite-svelte';
	import Icon from '../Icon.svelte';
	import BottomNavBarButton from './BottomNavBarButton.svelte';
	import { slide } from 'svelte/transition';
	import { beforeNavigate, goto } from '$app/navigation';
	import { get_session } from '$lib/session/session_initializer.svelte';
	import type { BeforeNavigate } from '@sveltejs/kit';
	import type { IconType } from '$lib/ui_types';
	import { page } from '$app/state';
	import { onMount } from 'svelte';
    import { header_menu_open } from '$lib/stores/header_menu_open_store';
	import { hide_bottom_nav_bar } from '$lib/stores/hide_bottom_nav_bar_store';
	import header_store from '$lib/stores/header_store.svelte';

    let {user_account_uuid} = get_session();

    type NavigationElement = {
        icon: IconType,
        label: string,
        target_path?: string,
        highlight_path_regex: RegExp,
        highlighted: boolean,
        children_open?: boolean
        children_elements?: NavigationElement[],
    }

    let main_elements: NavigationElement[] = $state([
        {
            icon: 'home',
            label: 'Home',
            target_path: '/feed',
            highlight_path_regex: /^\/feed$/g,
            highlighted: false,
            children_open: false,
        },
        {
            icon: 'chart_toggle',
            label: 'Stats',
            highlight_path_regex: /^\/home$/g,
            highlighted: false,
            children_elements: [
                {
                    icon: 'overview_stats',
                    label: 'Trends',
                    target_path: '/home',
                    highlight_path_regex: /^\/home$/g,
                    highlighted: false
                },
                {
                    icon: 'swim',
                    label: 'Swims',
                    target_path: `/home/${user_account_uuid}/swims`,
                    highlight_path_regex: new RegExp(`^(/home/${user_account_uuid}/swims)|(/(pools|tracks).*)$`),
                    highlighted: false
                },
            ]
        },
        {
            icon: 'social',
            label: 'Social',
            target_path: `/home/${user_account_uuid}/groups`,
            highlight_path_regex: /^$/g,
            highlighted: false,
            children_open: false,
            children_elements: [
                {
                    icon: 'groups_and_clubs',
                    label: 'Groups',
                    target_path: `/home/${user_account_uuid}/groups`,
                    highlight_path_regex: new RegExp(`^(/home/${user_account_uuid}/groups)|(/groups/.*)$`),
                    highlighted: false
                },
                {
                    icon: 'add_user',
                    label: 'Friends',
                    target_path: `/home/${user_account_uuid}/friends`,
                    highlight_path_regex: new RegExp(`^/home/${user_account_uuid}/friends.*$`),
                    highlighted: false
                },
            ]
        },
        {
            icon: 'workout',
            label: 'Workouts',
            target_path: '/workouts',
            highlight_path_regex: /^\/workouts.*$/g,
            highlighted: false
        },
        {
            icon: 'menu',
            label: 'More',
            highlighted: false,
            highlight_path_regex: /^$/g,
            children_open: false,
            children_elements: [
                {
                    icon: 'profile',
                    label: 'Profile',
                    target_path: `/home/${user_account_uuid}/profile`,
                    highlight_path_regex: new RegExp(`^/home/${user_account_uuid}/profile$`),
                    highlighted: false
                },
                {
                    icon: 'settings',
                    label: 'Settings',
                    target_path: `/home/settings`,
                    highlight_path_regex: /^\/home\/settings$/g,
                    highlighted: false
                },
                // {
                //     icon: 'logout',
                //     label: 'Logout',
                //     target_path: `/logout`,
                //     highlighted: false
                // },
            ]
        },
    ]);

    onMount(() => {

        let pathname = page.url.pathname ?? '/feed';
        update_state(pathname);
    });

    let y_scroll = $state(0);

    // let hide_bottom_nav = $derived(y_scroll > 0);
    let hide_bottom_nav = $state(false);
    let disqualified_page = $derived.by(() => {

        let path_name = page.url.pathname;
        return path_name.startsWith('/onboarding/') 
               || path_name.startsWith('/c/')
               || path_name.startsWith('/share/')
               || path_name === '/workouts/new'
               || path_name === '/groups/new'
               || path_name.match(/^\/groups\/[a-zA-Z0-9\-]*\/invite$/g) !== null
               || path_name.match(/^\/home\/[a-zA-Z0-9\-]*\/swims\/upload$/g) !== null
               || path_name.match(/^\/workouts\/[a-zA-Z0-9\-]*\/edit$/g) !== null
               || path_name.match(/^\/groups\/[a-zA-Z0-9\-]*\/edit$/g) !== null;
    });

    function close_speed_dials() {

        for (let element of main_elements) {
            element.children_open = false;
        }
    }

    function handle_scroll(event: Event) {

        close_speed_dials();

        let window_y = window.scrollY;

        let is_scroll_down = window_y > y_scroll;
        // console.log("Scrolling...", y_scroll, window_y, is_scroll_down);

        y_scroll = window_y;

        // only show if near top of screen
        if (!is_scroll_down && window_y >= 200) {
            return;
        }

        if (hide_bottom_nav !== is_scroll_down) {
            hide_bottom_nav = is_scroll_down;
        }
    }

    beforeNavigate(async (navigation: BeforeNavigate) => {

        // update icon highlight state
        let route_id = navigation.to?.route.id;
        if (route_id === undefined || route_id === null) {
            return;
        }

        let pathname = navigation.to?.url.pathname ?? '/feed';
        update_state(pathname);
    });

    function update_state(pathname: string) {

        for (let element of main_elements) {
            
            if (element.children_elements) {
                
                let child_highlighted = false;
                for (let child_element of element.children_elements) {

                    // console.log("Checking child: ", child_element.target_path, pathname);
                    if (pathname.match(child_element.highlight_path_regex) !== null) {
                        child_element.highlighted = true;
                        child_highlighted = true;
                    } else {
                        child_element.highlighted = false;
                    }
                }

                element.highlighted = child_highlighted;

            } else {
                // console.log("Checking: ", element.target_path, pathname);
                element.highlighted = pathname.match(element.highlight_path_regex) !== null;
            }
        }

    }

    function handle_nav_click(nav_element: NavigationElement) {

        close_speed_dials();

        if (nav_element.target_path === undefined) {
            return;
        }

        goto(nav_element.target_path);
    }

</script>

<!-- <svelte:window bind:scrollY={y_scroll} /> -->
<svelte:window onscroll={handle_scroll} />

{#if !hide_bottom_nav && !disqualified_page && !$header_menu_open && !$hide_bottom_nav_bar && header_store.is_bottom_nav_enabled}

    <div transition:slide={{duration: 150}} class="print:hidden max-w-80 w-full fixed bottom-8 border border-backdrop-light grid grid-cols-5 place-items-center bg-backdrop-dark rounded-l-full rounded-r-full py-2 px-4 gap-x-8 -translate-x-1/2 rtl:translate-x-1/2 start-1/2 z-[900]">
        
        {#each main_elements as main_element}
            <BottomNavBarButton onclick={() => {if (main_element.children_elements === undefined) {handle_nav_click(main_element);}}}
                                bind:highlighted={main_element.highlighted}
                                label={main_element.label}>
                {#snippet icon()}
                    {#if main_element.children_elements}
                        <SpeedDial bind:open={main_element.children_open} 
                                    defaultClass="relative" 
                                    tooltip="none" 
                                    pill={false} 
                                    popperDefaultClass="!space-y-2 !pb-2">
                            <div slot="button">
                                <Icon type={main_element.icon} 
                                      outline={false}
                                      class="size-7 mb-0.5" />       
                            </div>
                            {#each main_element.children_elements as child_element}
                                <SpeedDialButton name={child_element.label} 
                                                class="min-w-fit bg-backdrop-dark {child_element.highlighted ? 'text-primary' : 'text-primary-foreground-disabled'} gap-y-0 h-fit w-full"
                                                on:click={() => handle_nav_click(child_element)}
                                                textDefaultClass="text-[0.6rem]">
                                    <Icon type={child_element.icon} outline={false} class="size-5" />
                                </SpeedDialButton>
                            {/each}
                        </SpeedDial>        
                     {:else}
                        <Icon type={main_element.icon} class="size-7 mb-1" outline={false} />
                    {/if}
                {/snippet}
            </BottomNavBarButton>

        {/each}
    </div>
{/if}
