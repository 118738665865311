// import { hide_bottom_nav_bar } from "./hide_bottom_nav_bar_store";

export type HeaderType = 'main' | 'details';

class HeaderStore {

    private _type: HeaderType = $state('main');
    private _title: string | undefined = $state();

    get type(): HeaderType {
        return this._type;
    }

    get title(): string | undefined {
        return this._title;
    }

    set_type(value: HeaderType, title?: string) {
        this._type = value;
        this._title = title;
    }

    set_main() {
        this._type = 'main';
        this._title = undefined;
    }

    get is_bottom_nav_enabled(): boolean {
        return this._type === 'main';
    }
}

const header_store = new HeaderStore();
export default header_store;