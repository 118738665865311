<script lang="ts">
    import { page } from '$app/state';
    import { fade } from "svelte/transition";
    import SideBarLink from "./SideBarLink.svelte";
    import HeaderLink from "./HeaderLink.svelte";
    import UserAccountAvatar from '$lib/components/UserAccountAvatar.svelte';
    import { Capacitor } from '@capacitor/core';
    import { activity_sync_store } from '$lib/stores/activity_sync_store';
    import AlertDropdownComponent from '$lib/components/alert/AlertDropdownComponent.svelte';
    import { afterNavigate, beforeNavigate, goto } from '$app/navigation';
    import { show_login } from '$lib/stores/login_store';
    import env_client from '$lib/env/env_clientside';
    import features_framework_clientside from '$lib/features/features_framework_clientside';
    import { header_menu_enabled } from '$lib/stores/header_menu_enabled_store';
    import { Dropdown } from 'flowbite-svelte';
    import { get_session } from '$lib/session/session_initializer.svelte';
    import { header_menu_open } from '$lib/stores/header_menu_open_store';
    import Icon from '$lib/components/Icon.svelte';
	import header_store, { type HeaderType } from '$lib/stores/header_store.svelte';

    const HEADER_ALWAYS_VISIBLE = false;
    const is_ios = false; 

    afterNavigate(() => {

        $header_menu_open = false;
        profile_menu_open = false;

        let path_name = page.url.pathname;
        if (path_name.match(/^\/pools\/[a-zA-Z0-9\-]*\/view$/g) !== null) {
            header_store.set_type('details', 'Pool Swim');
        } else if (path_name.match(/^\/tracks\/[a-zA-Z0-9\-]*\/view$/g) !== null) {
            header_store.set_type('details', 'Open Water Swim');
        } else if (path_name.match(/^\/workouts\/[a-zA-Z0-9\-]*\/view$/g) !== null) {
            header_store.set_type('details', 'Pool Workout');
        } else if (path_name === '/integrations/manage/setup') {
            header_store.set_type('details', 'Integrations');
        } else {
            header_store.set_main();
        }

        // console.log("Header :", path_name, $state.snapshot(header_store.type));
    });

    let user_session = get_session();

    let profile_menu_open: boolean = $state(false);

    function toggle_popup_menu(event:any) {
        $header_menu_open = !$header_menu_open;
    }

    function toggle_profile_menu(event:any) {
        profile_menu_open = !profile_menu_open;
    }

    async function logout() {
        window.location.href = '/auth/logout';
    }

    show_login.subscribe((visible) => {
        if (visible) {
            $header_menu_open = false;
            profile_menu_open = false;
        }
    });

    function show_auth_modal() {
        show_login.set(true);
        $header_menu_open = false;
        profile_menu_open = false;
    }

    let is_onboarding = $derived(page.url.pathname.startsWith("/onboarding/"));

    function handle_header_backbutton() {
        history.back();
    }

</script>

{#if env_client.is_dev() && header_store.type === 'main'}
    <!--
        Emulator form factors:
        
        iPhone 14 Pro Max (vertical) - default
        iPhone 14 Pro Max (horizontal) - md
        iPad Pro (vertical) - lg
        iPad Pro (horizontal) - lg
        iPad Air/Mini (vertical) - md
        iPad Air/Mini (horizontal) - lg
        Galaxy Z Fold 5 (vertical - folded) - sm
        Galaxy Z Fold 5 (horizontal - folded) - md
    -->
    <div class="print:hidden absolute top-0 left-0 px-2 py-1 text-[0.6rem] rounded-lg bg-white z-[999]">
        <div class="block sm:hidden">
            Default
        </div>
        <div class="hidden sm:block md:hidden">
            SM
        </div>
        <div class="hidden md:block lg:hidden">
            MD
        </div>
        <div class="hidden lg:block xl:hidden">
            LG
        </div>
        <div class="hidden xl:block">
            XL
        </div>
    </div>
{/if}

<header class="print:bg-white flex-0 {HEADER_ALWAYS_VISIBLE ? `fixed ${is_ios ? 'pt-12 md:pt-4 bg-backdrop-dark' : ''} top-0 right-0 w-full` : ''}">

    <!-- Print-only header -->
    <div class="hidden print:flex print:place-content-end print:p-4">
        <div class="text-xs align-text-bottom gap-x-2 flex place-items-center">
            Powered by
            <img class="h-5 w-auto" src="/logo/swimerize-logo-light-bg.svg" alt=""/>
        </div>
    </div>

    <!-- Non-print screen header -->
    <div class="print:hidden bg-backdrop-dark py-1">

        <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 h-10 sm:h-14" aria-label="Global">

            {#if header_store.type === 'main'}
                <div class="flex flex-1 items-center">

                    <div class="flex w-full items-center justify-between md:w-auto">
                        {#if !is_onboarding && $header_menu_enabled}
                            <a href="{user_session?.user_settings ? '/feed' : '/'}">
                                <span class="sr-only">Swimerize</span>
                                <img class="h-6 w-auto sm:h-8" src="/logo/swimerize-logo-dark-bg.svg" alt="">
                            </a>
                        {:else}
                            <div>
                                <span class="sr-only">Swimerize</span>
                                <img class="h-6 w-auto sm:h-8" src="/logo/swimerize-logo-dark-bg.svg" alt="">
                            </div>
                        {/if}
                        {#if $activity_sync_store.active}
                            <div class="bg-backdrop-light text-primary-text rounded-md px-2 py-1 text-xs flex gap-2">
                                <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" 
                                    class="w-4 h-4 animate-rotate-360"
                                    style="animation-iteration-count: infinite; animation-duration: 1500ms">
                                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                                </svg>             -->
                                <Icon type={'sync'} 
                                        class="w-4 h-4 animate-rotate-360" 
                                        style="animation-iteration-count: infinite; animation-duration: 1500ms"/>

                                Apple Watch
                            </div>
                        {/if}
                        {#if $header_menu_enabled}
                            {#if !is_onboarding}
                                <div class="-mr-2 flex items-center md:hidden">
                                    <div class="flex place-items-center">
                                        {#if user_session?.user_account_uuid}
                                            <AlertDropdownComponent />
                                        {/if}
                                    </div>
                                    <button onclick={toggle_popup_menu} type="button" class="focus-ring-inset inline-flex items-center justify-center rounded-md bg-backdrop-dark p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white" aria-expanded="false">
                                        <span class="sr-only">Open main menu</span>
                                        <!-- Heroicon name: outline/bars-3 -->
                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                        </svg>
                                    </button>
                                </div>
                            {/if}
                        {/if}
                    </div>
                    <div class="hidden space-x-4 md:ml-10 md:flex items-center">
                        {#if $header_menu_enabled}
                            {#if user_session?.user_settings}
                                <HeaderLink url="/feed" text="Home" popup_menu={false}/>
                                <HeaderLink url="/home" text="Dashboard" popup_menu={false}/>
                                <HeaderLink url="/home/{user_session?.user_account_uuid}/groups" text="Groups" popup_menu={false}/>
                                <HeaderLink url="/workouts" text="Workouts" popup_menu={false}/>
                                <!-- <HeaderLink url="/activities" text="Swims" popup_menu={false}/> -->
                            {#if features_framework_clientside.is_courses_enabled()}
                                <HeaderLink url="/courses" text="Courses" popup_menu={false}/>
                            {/if}
                            {:else}
                                <!-- Turned off during Beta -->
                                <!-- <HeaderLink url="/activities" text="Swims" popup_menu={false}/>
                                <HeaderLink url="/courses" text="Courses" popup_menu={false}/> -->
                            {/if}
                        {/if}
                    </div>
                </div>

                <!-- Profile dropdown on larger screens (SM+)-->
                {#if $header_menu_enabled}
                    {#if user_session?.user_settings}

                        <div class="hidden md:flex">

                            {#if !is_onboarding}
                                <div class="flex place-items-center mr-4">
                                    {#if user_session?.user_account_uuid}
                                        <AlertDropdownComponent />
                                    {/if}
                                </div>
                            {/if}
                            
                            <button type="button" class="hidden md:flex max-w-xs items-center rounded-full text-sm focus:outline-none" 
                                    id="user-menu-button" 
                                    aria-expanded="false" 
                                    aria-haspopup="true">

                                <span class="sr-only">Open user menu</span>
                                <UserAccountAvatar user_settings={user_session?.user_settings} class="size-9"/>
                            </button>

                            <Dropdown bind:open={profile_menu_open} placement={'bottom-start'} class="p-2"> 

                                <!--
                                    Dropdown menu, show/hide based on menu state.
                                -->
                                <li >
                                    <!-- Active: "bg-gray-100", Not Active: "" -->
                                    <a href="/home/{user_session?.user_account_uuid}/profile" 
                                        onclick={toggle_profile_menu} 
                                        class="block py-2 px-4 text-sm text-gray-700" 
                                        role="menuitem" 
                                        tabindex="-1" 
                                        id="user-menu-item-1">

                                        Your Profile
                                    </a>
                                </li>

                                <li>
                                    <a href="/home/settings" 
                                        onclick={toggle_profile_menu} 
                                        class="block py-2 px-4 text-sm text-gray-700" 
                                        role="menuitem" 
                                        tabindex="-1" 
                                        id="user-menu-item-1">

                                        Account Settings
                                    </a>
                                </li>

                                <li> 
                                    <button onclick={logout} onkeypress={logout} 
                                            class="block py-2 px-4 text-sm text-gray-700" 
                                            role="menuitem" 
                                            tabindex="-1" 
                                            id="user-menu-item-2">

                                        Sign out
                                    </button>
                                </li>
                            </Dropdown>
                        </div>
                        <!-- <div class="hidden md:block ml-3" >
                        <div> -->
                        <!-- </div>
                        </div>   -->
                    {:else}
                        <div class="hidden sm:flex sm:flex-row sm:gap-5 sm:items-center">
                            <div class="flex-0 mx-auto ">
                                <!-- <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-1 text-base font-medium text-white hover:bg-gray-700">
                                    Sign up!
                                </a> -->
                            </div>                
                            <div class="mx-auto text-sm text-slate-300 hover:text-gray-300">
                                <span onclick={show_auth_modal} 
                                        onkeypress={show_auth_modal} 
                                        class="text-base font-medium text-slate-300 hover:text-gray-300"
                                        role="button"
                                        tabindex="0">

                                    <span class="underline">Sign up</span> <span class="text-xs mx-2">or</span> Log in

                                </span>
                                <!-- <a href="/auth/signin" class="text-base underline font-medium text-slate-300 hover:text-gray-300">
                                    - Log in alternate
                                </a> -->
                            </div>
                        </div>
                    {/if}
                {/if}
                <!-- <div class="hidden md:flex md:items-center md:space-x-6">
                    {#if session.is_logged_in}
                    <a href="/auth/logout" class="text-base font-medium text-white hover:text-gray-300">Log out</a>
                    {:else}
                    <a href="/auth/login" class="text-base font-medium text-white hover:text-gray-300">Log in</a>
                    <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">Sign up!</a>
                    {/if}
                </div> -->
            {:else if header_store.type === 'details'}
            
                <div class="flex place-content-between w-full">
                    
                    <!-- back button -->
                    <button class="text-primary-foreground-enabled flex place-content-start gap-x-4"
                            onclick={handle_header_backbutton}>
                        <Icon type={'back'} class="h-6 w-6 text-gray-300"/>
                        {header_store.title ?? 'Back'}
                    </button> 
                    <img class="h-6 w-auto" src="/logo/swimerize-logo-dark-bg.svg" alt=""/>
                </div>
                    
            {/if}
        </nav>
    </div>

    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. class:hidden={!sidebar_open} -->
    {#if $header_menu_open}
    
        <div class="relative z-[999] lg:hidden" role="dialog" aria-modal="true">
            <!--
            Off-canvas menu backdrop, show/hide based on off-canvas menu state.
            -->
            <div transition:fade
                class="fixed inset-0 bg-black bg-opacity-80">
            </div>
        
            <div class="fixed inset-0 z-40 flex">

                <!--
                    Off-canvas menu, show/hide based on off-canvas menu state.
                -->
                <div transition:fade 
                     class:pt-8={Capacitor.getPlatform() === 'android'} 
                     class="relative flex w-full max-w-xs flex-1 flex-col bg-backdrop-dark text-slate-300 focus:outline-none safe-top">

                    <!--
                    Close button, show/hide based on off-canvas menu state.
                    -->
                    <div transition:fade class="absolute top-0 right-0 -mr-12 pt-2">
                        <button onclick={toggle_popup_menu} type="button" class:mt-8={Capacitor.getPlatform() === 'android'} class="safe-top ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none">
                            <span class="sr-only">Close sidebar</span>
                            <!-- Heroicon name: outline/x-mark -->
                            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
            
                    <div class="pt-1 pb-5">
                        <div class="flex flex-shrink-0 items-center px-4 h-10">
                            <img class="h-6 w-auto" src="/logo/swimerize-logo-dark-bg.svg" alt="">
                        </div>
                        <nav aria-label="Sidebar" class="mt-5">
                            <div class="space-y-1 px-2">

                                {#if user_session?.user_settings}
                                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/feed" label="Home" icon_type={'home'} />
                                    <!-- outline/home -->

                                    <SideBarLink disable_selected_highlight={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home" label="Stats" icon_type={'chart_toggle'} />
                                    <SideBarLink is_sublink={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home" label="Trends" icon_type={'overview_stats'} />
                                    <SideBarLink is_sublink={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_session?.user_account_uuid}/swims" label="Your Swims" icon_type={'swim'} />

                                    <SideBarLink disable_selected_highlight={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_session?.user_account_uuid}/groups" label="Social" icon_type={'social'} />
                                    <SideBarLink is_sublink={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_session?.user_account_uuid}/groups" label="Groups & Clubs" icon_type={'groups_and_clubs'} />
                                    <SideBarLink is_sublink={true} mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_session?.user_account_uuid}/friends" label="Friends" icon_type={'add_user'} />

                                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/workouts" label="Workouts" icon_type={'workout'} />

                                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/home/{user_session?.user_account_uuid}/profile" label="Profile" icon_type={'profile'} />
                                    
                                {:else}

                                    <SideBarLink mobile={true} on:menu-item-click={toggle_popup_menu} url="/" label="Homepage" icon_type={'home'} />

                                {/if}
                            </div>
                        </nav>
                    </div>
                    {#if user_session?.user_settings}
                        <div class="flex flex-shrink-0 border-t border-gray-200 px-4 pt-10 mt-5">
                            <div class="flex items-start">
                                <UserAccountAvatar user_settings={user_session?.user_settings} class="inline-block size-10" />
                                <div class="ml-3">
                                    <a href="/home/settings" class="group block flex-shrink-0" onclick={toggle_popup_menu} onkeypress={toggle_popup_menu}>
                                        <p class="text-base font-medium text-slate-300">{user_session?.user_settings.display_name}</p>
                                        <p class="text-sm font-medium text-primary-text-diminished">Account Settings</p>
                                    </a>
                                    <p class="mt-4 text-sm font-medium text-primary-text-diminished">
                                        <span onclick={logout} onkeypress={logout} role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    {:else}
                        <div class="flex flex-col gap-5 pt-10 my-5 border-t border-gray-200">
                            <!-- <div class="flex-0 mx-auto ">
                            <a href="/signup/" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">
                                Sign up!
                            </a>
                            </div>                 -->
                            <div class="mx-auto text-sm text-slate-300 hover:text-gray-300 justify-end">
                                <span onclick={show_auth_modal} 
                                        onkeypress={show_auth_modal} 
                                        class="text-base font-medium text-slate-300 hover:text-gray-300"
                                        role="button"
                                        tabindex="0">
                                    <span class="underline">Sign up</span> <span class="text-xs font-light mx-2">or</span> Log in
                                </span>
                            </div>
                        </div>
                    {/if}                    
                </div>
            
                <div class="w-14 flex-shrink-0" aria-hidden="true">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>
    {/if}
</header>

{#if HEADER_ALWAYS_VISIBLE}
    <div class="mb-12 md:mb-16">

    </div>
{/if}
