<script lang="ts">
  	import { createEventDispatcher } from "svelte";
    import { page } from '$app/state';
	import type { IconType } from "$lib/ui_types";
	import Icon from "$lib/components/Icon.svelte";

    const dispatch = createEventDispatcher();
    function handleClick(event:any) {

        dispatch('menu-item-click');
    }

    interface Props {
        icon_type: IconType;
        url: string;
        label: string;
        mobile: boolean;
        disable_selected_highlight?: boolean;
        is_sublink?: boolean;
    }

    let {
        icon_type,
        url,
        label,
        mobile,
        disable_selected_highlight = false,
        is_sublink = false
    }: Props = $props();

    let selected = $derived.by(() => {
        
        let to_return = false;
        if (is_sublink) {

            let url_pathname = new URL(url, page.url).pathname;
            to_return = page.url.pathname === url_pathname;
        } else {

            to_return = page.url.pathname === url && !disable_selected_highlight;
        }

        return to_return;
    });

</script>

{#if mobile}

    <a href="{url}" 
       onclick={handleClick} 
       class:bg-slate-700={selected} 
       class="group flex items-center rounded-md p-2 text-base font-medium text-slate-300">

        <Icon type={icon_type}
              outline={false}
              class="{is_sublink ? 'ml-10' : ''} mr-4 h-6 w-6 text-slate-300" />            
        {label}
    </a>

{:else}

    <a href="{url}" onclick={handleClick} class:bg-slate-700={selected} class="flex items-center rounded-lg p-4 text-indigo-200 hover:bg-indigo-700">

        <Icon type={icon_type}               
              outline={false}
              class="{is_sublink ? 'ml-10' : ''} size-6" />
        <span class="sr-only">{label}</span>
    </a>

{/if}


