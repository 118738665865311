import { browser } from '$app/environment';
import feed_state_store from '$lib/client/indexeddb/feed_store.js';
import env_client from '$lib/env/env_clientside';
import { initialize_session } from '$lib/session/session_initializer.svelte.js';

// Disable SSR when running the dev server
// TODO: Remove altogether when https://github.com/vitejs/vite/issues/11468 fixed
export const ssr = env_client.is_ssr_enabled();

/** @type {import('./$types').PageLoad} */
export async function load({data, fetch, params, url, parent}) {

    if (data.user_settings) {

        // clear previous cached feed state if page is first loaded
        if (browser) {
            await feed_state_store.clear_feed_state();
        }
    }

    return {
        ...data
    };
}