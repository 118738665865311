<script lang="ts">

interface Props {
        icon: import('svelte').Snippet;
        label?: string;
        onclick?: () => void;
        highlighted: boolean;
    }

    let {
        icon,
        label,
        onclick = async () => {},
        highlighted = $bindable(false)
    }: Props = $props();

</script>

<button class:text-primary={highlighted} 
        class:text-primary-foreground-disabled={highlighted === false}
        {onclick}
        class="flex flex-col items-center justify-center w-full h-full">
    {@render icon()}
    {#if label}
        <span class="text-[0.6rem]">{label}</span>
    {/if}
</button>
